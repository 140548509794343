import { gql } from '@apollo/client';
import { FEATURE_BLOCKS_FRAGMENT } from './fragments/featureBlocks';
import { LIST_PRODUCT_FRAGMENT } from './fragments/listProduct';

export const START_PAGE_QUERY = gql`
  ${FEATURE_BLOCKS_FRAGMENT}
  ${LIST_PRODUCT_FRAGMENT}

  query startPage(
    $systemId: ID
    $locale: String
    $productImageOptions: SanityImageOptions
  ) {
    products(systemId: $systemId, locale: $locale, channel: web) {
      ...ListProduct
    }
    giftableProducts: products(
      systemId: $systemId
      locale: $locale
      channel: web
      giftable: true
    ) {
      ...ListProduct
    }
    system {
      id
      inSeason
      name
      sponsor
      GBFS
      publicTripHistory
    }
    startPage(systemId: $systemId, locale: $locale) {
      opengraph {
        title
        description
        images
      }
      heading
      primaryCtaButtonText
      features {
        _key
        title
        description
        image
      }
      heroVideo
      heroImageLg: heroImage(options: { width: 1600 }) {
        url
      }
      heroImageMd: heroImage(options: { width: 1000 }) {
        url
      }
      heroImageSm: heroImage(options: { width: 800 }) {
        url
      }
      featureBlocks {
        ...FeatureBlocks
      }
    }
    settings: sanitySettings {
      title
    }
  }
`;
