import dynamic from 'next/dynamic';
import { H1, Box, Container } from '../../components';
import { startPage_startPage } from '../../core-types';

const FeatureBlocks = dynamic(() => import('../FeatureBlocks'));

type Props = {
  startPage: startPage_startPage;
  appContent: boolean;
};

export default function StartPage({ startPage, appContent }: Props) {
  return (
    <>
      <Container variant="narrow" data-testid="StartPage">
        <Box textAlign="center" mb="md">
          <H1 mt="md" mb="sm">
            {startPage.heading}
          </H1>
        </Box>
      </Container>
      <FeatureBlocks
        featureBlocks={startPage.featureBlocks}
        appContent={appContent}
      />
    </>
  );
}
