import { useEffect } from 'react';
import { START_PAGE_QUERY } from '../lib/core/StartPageQuery';
import { isPreviewMode } from '../lib/util';
import StartPage from '../modules/StartPage';
import Layout from '../modules/Layout';
import { CustomNextPage } from '../lib/types';
import {
  startPage,
  startPageVariables,
  SanityImageOptionFit,
  SanityImageOptionCrop
} from '../core-types';
import { bootstrap } from '../lib/bootstrap';
import dynamic from 'next/dynamic';
import { useQuery } from '@apollo/client';

const PreviewMode = dynamic(() => import('../modules/StartPage/PreviewMode'));
const MissingOnboardingDialog = dynamic(() =>
  import('../modules/MissingOnboardingDialog')
);

const variables = {
  productImageOptions: {
    width: 720,
    height: 480,
    fit: SanityImageOptionFit.crop,
    crop: SanityImageOptionCrop.entropy
  }
};

const Home: CustomNextPage<{}> = props => {
  const { systemConfig, url } = props;
  const { data, loading } = useQuery<startPage, startPageVariables>(
    START_PAGE_QUERY,
    { variables }
  );
  const { query } = url;
  const previewMode = isPreviewMode(systemConfig, url);

  useEffect(() => {
    // just for testing errors in cypress
    if (query.__client_error === '1') {
      throw new Error('__client_error=1');
    }
  }, [query]);

  if (query.__server_error === '1') {
    throw new Error('__server_error=1');
  }

  return (
    <Layout
      url={url}
      loading={loading}
      opengraph={data && data.startPage && data.startPage.opengraph}
      contentProps={
        previewMode
          ? { mb: 0, py: 0, mx: 0 }
          : {
              mx: 0,
              mb: 0,
              py: 0
            }
      }
      render={({ appContent }) => {
        if (!data || !data.startPage || !data.settings) {
          return null;
        }
        if (previewMode) {
          return <PreviewMode startPage={data.startPage} />;
        }
        return (
          <>
            <MissingOnboardingDialog />
            <StartPage startPage={data.startPage} appContent={appContent} />
          </>
        );
      }}
    />
  );
};

Home.getInitialProps = async ctx => {
  await ctx.apolloClient.query<startPage, startPageVariables>({
    query: START_PAGE_QUERY,
    variables
  });
};

export default bootstrap(Home);
