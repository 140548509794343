import { gql } from '@apollo/client';

export const LIST_PRODUCT_FRAGMENT = gql`
  fragment ListProduct on Product {
    id
    descriptionText
    name
    image(options: $productImageOptions) {
      url
    }
    slug {
      current
    }
    price
    campaign
    purchasable
  }
`;
